import imageEducators from '../images/educators.jpg'
import imageResearch from '../images/research.jpg'
import imageTeach from '../images/teach.jpg'

const imageHeight = 340
const imageWidth = 655

export default [
  {
    excerpt:
      'Young people can use coding to solve problems that matter to them, and to express themselves creatively',
    image: {
      alt: '',
      height: imageHeight,
      src: imageTeach,
      width: imageWidth,
    },
    title: 'Teaching young people all over the world to code',
  },
  {
    excerpt:
      'We invest in leading-edge research into computing education, and we rigorously evaluate our programmes',
    image: {
      alt: '',
      height: imageHeight,
      src: imageResearch,
      width: imageWidth,
    },
    title: 'Groundbreaking research',
  },
  {
    excerpt:
      'Teachers, educators, and volunteers train with us in how to best support and inspire their learners',
    image: {
      alt: '',
      height: imageHeight,
      src: imageEducators,
      width: imageWidth,
    },
    title: 'Empowering educators',
  },
]
