import './SupportersClub.scss'

import Container from '@/components/shared/container'
import React from 'react'
import Section from '@/components/shared/section'
import TrackedLink from '@/components/shared/tracked-link'
import image from './images/logo-supporters-club.png'

const SupportersClub = () => (
  <Section>
    <Container>
      <div className="c-support-us-supporters-club">
        <figure className="c-support-us-supporters-club__figure">
          <img
            alt="Supporters Club logo"
            className="c-support-us-supporters-club__image"
            height={225}
            src={image}
            width={500}
          />
        </figure>
        <div className="c-support-us-supporters-club__content">
          <h2 className="sc-rp-type-secondary-heading c-support-us-supporters-club__heading">
            Join the Supporters Club
          </h2>
          <p>
            A network of generous individuals and businesses who donate to help
            us realise our mission: to put the power of digital-making in the
            hands of more young people across the globe.{' '}
          </p>

          <p>
            Our members include some of the world’s leading technologists,
            engineers and educators.
          </p>

          <p>
            Stay close to our work and see the direct impact of your donation on
            young people's lives.
          </p>

          <TrackedLink
            className="sc-rp-button"
            eventCategory="Signpost: Support Us"
            eventLabel="Supporters Club: CTA button"
            to="mailto:partners@raspberrypi.org"
          >
            Find out more
          </TrackedLink>
        </div>
      </div>
    </Container>
  </Section>
)

export default SupportersClub
