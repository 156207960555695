import PropTypes from 'prop-types'
import React from 'react'
import Type from '../'

const HeadingBase = ({ children, className, element, ...rest }) => (
  <Type
    className={className}
    element={element}
    sauceClassName="c-type-heading-base"
    {...rest}
  >
    {children}
  </Type>
)

HeadingBase.defaultProps = {
  element: 'h3',
}

HeadingBase.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
  className: PropTypes.string,
  element: PropTypes.string,
}

export default HeadingBase
