import './FundraiseForUs.scss'

import CtaBox from '@/components/shared/CtaBox'
import React from 'react'
import imageFundraise from './images/biking.jpg'
import imageFundraiseLg from './images/biking-lg.jpg'

const FundraiseForUs = () => {
  return (
    <CtaBox
      heading="Fundraise for us"
      link={{
        eventCategory: 'Signpost: Support Us',
        eventLabel: 'Individual Giving: CTA button',
        text: 'Fundraise for us',
        to: 'mailto:partners@raspberrypi.org',
      }}
    >
      <div className="c-support-us-fundraise-for-us">
        <p className="c-support-us-fundraise-for-us__text">
          Whether it’s running a marathon, organising a coding challenge, or
          dedicating your birthday, you can raise funds for us by spreading the
          word to your network of friends.
        </p>
        <figure className="c-support-us-fundraise-for-us__figure">
          <picture className="c-support-us-fundraise-for-us__picture">
            <source media="(min-width: 500px)" srcSet={imageFundraiseLg} />
            <img
              alt="Cyclists on a road by the beach"
              className="c-support-us-fundraise-for-us__image"
              height="240"
              src={imageFundraise}
              width="510"
            />
          </picture>
          <figcaption className="c-support-us-fundraise-for-us__caption">
            Christer Holloman plans to do the Ironman in Mexico and has already{' '}
            <strong>raised £3,443 for CoderDojo.</strong>
          </figcaption>
        </figure>
      </div>
    </CtaBox>
  )
}

export default FundraiseForUs
