import './CorporatePartner.scss'

import Container from '@/components/shared/container'
import React from 'react'
import Section from '@/components/shared/section'
import TrackedLink from '@/components/shared/tracked-link'
import WhyPartner from './WhyPartner'
import logoAtlassian from './images/logo-atlassian.png'
import logoBnyMellon from './images/logo-bny-mellon.png'
import logoGoogle from './images/logo-google.png'

const eventCategory = 'Signpost: Support Us page'

const partnersData = [
  {
    imageAlt: 'Google logo',
    imageSrc: logoGoogle,
    url: 'https://www.google.com/',
  },
  {
    imageAlt: 'Atlassian logo',
    imageSrc: logoAtlassian,
    url: 'https://www.atlassian.com/',
  },
  {
    imageAlt: 'BNY Mellon logo',
    imageSrc: logoBnyMellon,
    url: 'https://www.bnymellon.com/',
  },
]

const CorporatePartner = () => (
  <Section>
    <Container backgroundColor={'rgb(240, 248, 255)'}>
      <div className="c-support-us-corporate-partner">
        <div className="c-support-us-corporate-partner__partners">
          <h2 className="sc-rp-type-secondary-heading c-support-us-corporate-partner__heading">
            Become a corporate partner
          </h2>

          <ul className="c-support-us-corporate-partner__logos">
            {partnersData.map((partner, index) => (
              <li key={index}>
                <TrackedLink
                  eventCategory={eventCategory}
                  eventLabel="Corporate Partner: Partner link"
                  to={partner.url}
                >
                  <img
                    src={partner.imageSrc}
                    alt={partner.imageAlt}
                    height="180"
                    width="610"
                  />
                </TrackedLink>
              </li>
            ))}
          </ul>

          <div className="c-support-us-corporate-partner__content">
            <p>
              Our work is made possible because of a fantastic and growing{' '}
              <TrackedLink
                className="sc-rp-link"
                eventCategory={eventCategory}
                eventLabel="Corporate Partner: Community of individuals & orgs link"
                to="/about/supporters/"
              >
                community of individuals and organisations
              </TrackedLink>{' '}
              that partner with us because they share our mission.
            </p>

            <p>
              Become a corporate partner and work with us to create and enable
              the next generation of digital makers; please get in touch to
              discuss mutually beneficial partnership options.
            </p>

            <TrackedLink
              className="sc-rp-button"
              eventCategory={eventCategory}
              eventLabel="Corporate Partner: Become partner button"
              to="mailto:partners@raspberrypi.org"
            >
              Become a corporate partner
            </TrackedLink>
          </div>
        </div>
        <WhyPartner />
      </div>
    </Container>
  </Section>
)

export default CorporatePartner
