import './MakeADifference.scss'

import Container from '@/components/shared/container'
import { HeadingBase } from '@/components/shared/Type'
import React from 'react'
import Section from '@/components/shared/section'
import featuresData from './config/features-data'

const MakeADifference = () => (
  <Section>
    <Container>
      <div className="c-make-a-difference">
        <h2 className="sc-rp-type-secondary-heading">
          How we make a difference
        </h2>

        <ul className="c-make-a-difference__features">
          {featuresData.map((feature, index) => (
            <li key={index} className="c-make-a-difference__features-item">
              <figure className="c-make-a-difference__features-figure">
                <img
                  className="c-make-a-difference__features-image"
                  {...feature.image}
                />
              </figure>
              <div className="c-make-a-difference__features-content">
                <HeadingBase>{feature.title}</HeadingBase>
                <p className="c-make-a-difference__features-excerpt">
                  {feature.excerpt}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  </Section>
)

export default MakeADifference
