import './Type.scss'

import HeadingBase from './HeadingBase'
import PropTypes from 'prop-types'
import React from 'react'
import cx from 'bem-classnames'

const Type = ({ children, className, element, sauceClassName, ...rest }) => {
  const classNames = cx([sauceClassName, className]) || null
  const Element = element

  return (
    <Element className={classNames} {...rest}>
      {children}
    </Element>
  )
}

Type.defaultProps = {
  className: '',
  element: 'p',
  sauceClassName: '',
}

Type.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
  className: PropTypes.string,
  element: PropTypes.string,
  sauceClassName: PropTypes.string,
}

export { HeadingBase }

export default Type
