import './support-us.scss'

import ApplicationLayout from '@/Layouts/Application'
import CorporatePartner from '@/components/SupportUs/CorporatePartner'
import Hero from '@/components/SupportUs/Hero'
import IndividualGiving from '@/components/SupportUs/IndividualGiving'
import MakeADifference from '@/components/SupportUs/MakeADifference'
import PageMeta from '@/components/Application/PageMeta'
import React from 'react'
import SupportersClub from '@/components/SupportUs/SupportersClub'
import WorkplaceGiving from '@/components/SupportUs/WorkplaceGiving'
import opengraphImageSrc from './images/opengraph.png'
import pageMeta from './config/meta.json'

const SupportUsPage = () => {
  const { description, title } = pageMeta
  return (
    <ApplicationLayout>
      <PageMeta
        description={description}
        image={opengraphImageSrc}
        title={title}
      />
      <Hero />
      <MakeADifference />
      <CorporatePartner />
      <SupportersClub />
      <WorkplaceGiving />
      <IndividualGiving />
    </ApplicationLayout>
  )
}

export default SupportUsPage
