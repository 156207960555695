import './CtaBox.scss'

import { HeadingBase } from '@/components/shared/Type'
import PropTypes from 'prop-types'
import React from 'react'
import TrackedLink from '@/components/shared/tracked-link'

const CtaBox = ({ children, heading, link, ...rest }) => {
  const linkProps = { ...link }
  delete linkProps.text

  return (
    <div className="c-cta-box" {...rest}>
      <HeadingBase className="c-cta-box__heading">{heading}</HeadingBase>

      <div className="c-cta-box__content">{children}</div>

      {link && (
        <TrackedLink className="sc-rp-button c-cta-box__button" {...linkProps}>
          {link.text}
        </TrackedLink>
      )}
    </div>
  )
}

CtaBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  heading: PropTypes.string.isRequired,
  link: PropTypes.shape({
    eventAction: PropTypes.string,
    eventCategory: PropTypes.string,
    eventLabel: PropTypes.string,
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
}

export default CtaBox
