import './IndividualGiving.scss'

import Container from '@/components/shared/container'
import CtaBox from '@/components/shared/CtaBox'
import FundraiseForUs from './FundraiseForUs'
import React from 'react'
import Section from '@/components/shared/section'

const eventCategory = 'Signpost: Support Us'
const eventLabel = 'Individual Giving: CTA button'

const IndividualGiving = () => {
  return (
    <Section>
      <Container>
        <div className="c-support-us-individual-giving">
          <h2 className="sc-rp-type-secondary-heading">Individual Giving</h2>

          <div className="c-support-us-individual-giving__drivers">
            <CtaBox
              heading="Become a monthly donor"
              link={{
                'aria-hidden': 'true',
                eventCategory,
                eventLabel,
                text: 'Donate monthly',
                to: '/donate/',
              }}
            >
              <p>
                With £10 a month you will support a child to attend a free
                coding club for a whole year.
              </p>
            </CtaBox>
            <CtaBox
              heading="Make a one-off donation"
              link={{
                eventCategory,
                eventLabel,
                text: 'Make a donation',
                to: '/donate/',
              }}
            >
              <p>With £75 you will support the set up of a new coding club.</p>
            </CtaBox>

            <FundraiseForUs />
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default IndividualGiving
