import './WhyPartner.scss'

import { HeadingBase } from '@/components/shared/Type'
import React from 'react'
import TrackedLink from '@/components/shared/tracked-link'
import iconOpportunities from './images/icon-opportunities.svg'
import iconResearch from './images/icon-research.svg'
import iconWorldwide from './images/icon-worldwide.svg'

const eventCategory = 'Signpost: Support Us page'

const reasonsData = [
  {
    iconSrc: iconWorldwide,
    text:
      'We have teams in Ireland, UK, US, and India. We partner with over 50 organisations worldwide. We support activities in more than 179 countries.',
  },
  {
    iconSrc: iconResearch,
    text: (
      <>
        We invest in leading-edge research, and rigorously evaluate the impact
        of our activities on user learning.{' '}
        <TrackedLink
          className="sc-rp-link"
          eventCategory={eventCategory}
          eventLabel="Corporate Partner: Reason link"
          to="/research/"
        >
          Read our research publications
        </TrackedLink>
      </>
    ),
  },
  {
    iconSrc: iconOpportunities,
    text:
      'We offer employee engagement and volunteering opportunities through our globally recognised programmes, CoderDojo and Code Club, and our showcase event, Coolest Projects.',
  },
]

const WhyPartner = () => (
  <div className="c-support-us-why-partner">
    <HeadingBase>Why partner with us</HeadingBase>
    <ul className="c-support-us-why-partner__reasons">
      {reasonsData.map((reason, index) => (
        <li key={index} style={{ backgroundImage: `url(${reason.iconSrc})` }}>
          {reason.text}
        </li>
      ))}
    </ul>
  </div>
)

export default WhyPartner
