import './hero.scss'

import React from 'react'
import TrackedLink from '@/components/shared/tracked-link'

const Hero = () => (
  <header className="c-support-us-hero">
    <h1 className="c-support-us-hero__title">Support us</h1>

    <div className="c-support-us-hero__content">
      <p className="c-support-us-hero__copy">
        You can empower young people to change their world using technology.
      </p>

      <p className="c-support-us-hero__copy">
        Through supporting us, you will give young people the opportunity to
        shape their future.
      </p>

      <TrackedLink
        className="sc-rp-button c-support-us-hero__button"
        eventCategory="Signpost: Support Us"
        eventLabel="Hero: CTA button"
        to="/donate/"
      >
        Donate today
      </TrackedLink>
    </div>
  </header>
)

export default Hero
