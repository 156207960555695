import './WorkplaceGiving.scss'

import Container from '@/components/shared/container'
import React from 'react'
import Section from '@/components/shared/section'
import TrackedLink from '@/components/shared/tracked-link'
import imageLg from './images/workplace-giving--lg.jpg'
import imageSm from './images/workplace-giving--sm.jpg'

const eventCategory = 'Signpost: Support Us'

const image = {
  alt: 'Three children and an adult standing in front of a coding stand',
  height: 371,
  sources: [
    {
      media: '(min-width: 800px)',
      srcSet: imageLg,
    },
  ],
  src: imageSm,
  width: 560,
}

const WorkplaceGiving = () => (
  <Section>
    <Container backgroundColor={'rgb(255,237,237)'}>
      <div className="c-support-us-workplace-giving">
        <h2 className="sc-rp-type-secondary-heading c-support-us-workplace-giving__heading">
          Workplace giving
        </h2>

        <figure className="c-support-us-workplace-giving__figure">
          <picture className="c-support-us-workplace-giving__picture">
            {image.sources.map((source, index) => (
              <source key={index} {...source} />
            ))}

            <img
              alt={image.alt}
              className="c-support-us-workplace-giving__image"
              height={image.height}
              src={image.src}
              width={image.width}
            />
          </picture>
          <figcaption className="c-support-us-workplace-giving__figcaption">
            <p>
              CoderDojo volunteer Stephen Dietz uses matched giving for his
              volunteer hours, with his employer Mastercard matching over £2,000
              for CoderDojo.
            </p>
          </figcaption>
        </figure>
        <div className="c-support-us-workplace-giving__content">
          <p>
            Boost your CSR programmes, increase employee engagement and support
            a charity you love.
          </p>

          <p>
            Whether your employees are fundraising, volunteering or donating, by
            signing up to Benevity, YourCause, or Global Giving, you can match
            their contributions and double the impact.
          </p>

          <TrackedLink
            className="sc-rp-button"
            eventCategory={eventCategory}
            eventLabel="Workplace Giving: CTA button"
            to="mailto:partners@raspberrypi.org"
          >
            Set up workplace giving
          </TrackedLink>
        </div>
      </div>
    </Container>
  </Section>
)

export default WorkplaceGiving
